import { combineReducers } from 'redux'
import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import authReducers from './authReducers';
import errorReducers from './errorReducers';
import profileReducer from './profileReducer';
import homeReducer from './homepageReducer';


const rootReducers = combineReducers({
    auth: authReducers,
    profile: profileReducer,
    error: errorReducers,
    home: homeReducer
})


const middleware = [
    ...getDefaultMiddleware({
        thunk: true
    })
];

const store = configureStore({
    reducer: rootReducers,
    middleware,
    devTools: process.env.NODE_ENV !== "production",
});

export default store