import React from 'react';
import Home from '../Components/HomePage/home';
import Faq from '../Components/Faq/Faq';
import PrivacyPolicy from '../Components/Contents/privacyPolicy';
import LegalPolicy from '../Components/Contents/legalPolicy';
import Aboutus from '../Components/Contents/aboutus';
import Login from '../Components/Login/login';
import Signup from '../Components/Signup/signup';
import Contactus from '../Components/Contents/contactus';
import Recipes from '../Components/Recipes/recipes';

const Profile = React.lazy(() => import('../Components/Profile/profile'));

const routeItems = [
    { path: '/login', component: <Login />, layout: 'mainLayout' },
    { path: '/signup', component: <Signup />, layout: 'mainLayout' },
    { path: '/', component: <Home />, layout: 'mainLayout' },
    { path: '/profile', component: <Profile />, layout: 'mainLayout' },
    { path: '/faqs', component: <Faq />, layout: 'mainLayout' },
    { path: '/privacy-policy', component: <PrivacyPolicy />, layout: 'mainLayout' },
    { path: '/legal-policy', component: <LegalPolicy />, layout: 'mainLayout' },
    { path: '/about-us', component: <Aboutus />, layout: 'mainLayout' },
    { path: '/contact-us', component: <Contactus />, layout: 'mainLayout' },
    { path: '/recipes', component: <Recipes />, layout: 'mainLayout' },
]

export default routeItems