import React from 'react';
import '../App.scss';

export default function Lightbox({ openBox, image, closeBox }) {
    return (
        <div>
            {/* ########## Confirm Delete modal ############### */}

            <div className='lightbox-wrapper' style={{ display: openBox ? 'block' : 'none' }} >
                <div className='sticky-close'>
                    <img src='./svg-icons/close-white.svg' alt='' onClick={closeBox} />
                </div>
                <div className="lightbox" id="img1" style={{ display: openBox ? 'block' : 'none' }}>
                    <span >
                        <img src={image} alt='' />
                    </span>
                </div>
            </div>
        </div>
    )
}
