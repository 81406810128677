import React, { Fragment, useState, useEffect } from 'react';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import '../Login/login.scss';
import { useDispatch, useSelector } from 'react-redux';
import { userSignup, verifyUser, forgotPassword, socialLogin } from '../../Redux/ActionCreators/authAction';
import { useLocation, useNavigate } from 'react-router-dom';
import { Alert, Snackbar } from '@mui/material';
import { clearErrors } from '../../Redux/ActionCreators/errorAction';
import { LoginSocialFacebook, LoginSocialGoogle, LoginSocialApple } from 'reactjs-social-login';

const Signup = () => {
    const [otpEnable, setOtpEnable] = useState(false);
    const [loading, setLoading] = useState(false);
    const [isOtpResetLoading, setIsOtpResetLoading] = useState(false);
    const [successEnable, setSuccessEnable] = useState(false);
    const [messageText, setMessageText] = useState('');
    const [timer, setTimer] = useState(60);
    const [isRunning, setIsRunning] = useState(false);
    const error = useSelector(state => state.error);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const otp = { otp: '' }

    // Check if the user is already logged in
    useEffect(() => {
        // Replace this condition with your actual check for whether the user is logged in
        const userIsLoggedIn = localStorage.getItem('userAuthToken') !== null
        if (userIsLoggedIn) {
            // Redirect to the dashboard page
            navigate('/');
        }
    }, [location]);

    useEffect(() => {
        let interval;

        if (isRunning && timer > 0) {
            interval = setInterval(() => {
                setTimer(timer - 1);
            }, 1000);
        } else if (timer === 0) {
            clearInterval(interval);
        }

        return () => {
            clearInterval(interval);
        };
    }, [timer, isRunning]);

    const initialValues = {
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        password: '',
        confirm_password: ''
    };

    // Define the validation schema using Yup
    const validationSchema = Yup.object({
        firstName: Yup.string()
            .matches(/^[A-Za-z\s]+$/, 'Only alphabets and spaces are allowed')
            .required('First Name is required'),
        lastName: Yup.string()
            .matches(/^[A-Za-z\s]+$/, 'Only alphabets and spaces are allowed')
            .required('Last Name is required'),
        email: Yup.string()
            .required('Email is required')
            .matches(
                /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                'Invalid email address'
            ),
        phone: Yup.string().notRequired()
            .matches(/^\d{10}$/, 'Phone number must be 10 digits'),
        password: Yup.string().min(8, 'Min 8 char allowed')
            .max(15, 'Max 15 char allowed').required('Password is required'),
        confirm_password: Yup.string()
            .oneOf([Yup.ref('password'), null], 'Passwords must match')
            .required('Confirm Password is required')
    });

    // Define the form submission handler
    const handleSubmit = async (values) => {
        let obj = {
            "firstName": values.firstName,
            "lastName": values.lastName,
            "email": values.email,
            "phone": String(values.phone),
            "password": values.password
        }
        setLoading(true);
        const res = await dispatch(userSignup(obj));
        if (res.type === 'USER_REGISTER') {
            setOtpEnable(true);
            setIsRunning(true);
        }
        setLoading(false);
    };

    const otpSchema = Yup.object({
        otp: Yup.string()
            .length(4, 'OTP must be 4 characters long')
            .required('OTP is required'),
    })

    const resendOtp = async () => {
        let obj = {
            "email": localStorage.getItem('email'),
            "otpFor": "register"
        }
        const res = await dispatch(forgotPassword(obj));
        if (res?.type === 'FORGET_PASSWORD') {
            setTimer(60);
            setIsRunning(true);
        }
    }

    const verify = async (values) => {
        setIsOtpResetLoading(true);
        const obj = {
            email: localStorage.getItem('email'),
            otpNumber: values?.otp
        }
        const isVerify = await dispatch(verifyUser(obj));
        if (isVerify?.type === 'USER_LOGIN') {
            resetTimer();
            (localStorage.getItem('userAuthToken') !== null) ?
                navigate('/')
                : navigate('/login')
        }
        setIsOtpResetLoading(false);
    }

    const snackClosed = () => {
        dispatch(clearErrors());
        setSuccessEnable(false);
        setMessageText('');
    }

    const resetTimer = () => {
        setTimer(60);
        setIsRunning(false);
    };

    const minutes = Math.floor(timer / 60);
    const seconds = timer % 60;

    return (
        <div className='login-module h-95' style={{ backgroundImage: window.innerWidth > 767 ? '' : `url('./images/img1.png')` }}>
            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'center', }}
                open={error.successError}
                autoHideDuration={3000}
                onClose={snackClosed}
            >
                <Alert onClose={snackClosed} severity="error" sx={{ width: '100%' }}>
                    {error.messageText}
                </Alert>
            </Snackbar>
            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'center', }}
                open={error.successMessage}
                autoHideDuration={3000}
                onClose={snackClosed}
            >
                <Alert onClose={snackClosed} severity="success" sx={{ width: '100%' }}>
                    {error.messageText}
                </Alert>
            </Snackbar>
            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'center', }}
                open={successEnable}
                onClose={snackClosed}
                message={messageText}
                autoHideDuration={4000}
            />
            <div className=''>
                {window.innerWidth > 767 && <img src='./images/img1.png' className='bg-image signup-img' alt='' />}
                <div className='row'>
                    <div className='col-md-7'>
                        <div className="login-text">
                            <section>Spice of Life</section>
                            <p className='mt-4'>Cinnamon, a popular spice, not only adds flavor but also helps regulate blood sugar, making it a healthy culinary choice.</p>
                        </div>
                    </div>
                    <div className='col-md-5'>
                        <div className="login-holder signup">
                            <div className="login-box">
                                {
                                    !otpEnable ?
                                        <Fragment>
                                            <h6 className="text-left">Signup</h6>
                                            <p style={{ color: '#8D8C8F', fontSize: 16, fontWeight: 400 }}>Fill in the form to register your account</p>
                                            <div className='signup_form mt-4'>

                                                <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
                                                    {() => (
                                                        <Form >
                                                            <div className="row">
                                                                <div className="col-12 col-md-6">

                                                                    <div className="form-group">
                                                                        <Field type="text" name="firstName" autoComplete='off' className="form-control" placeholder="First Name *" />
                                                                        <ErrorMessage name="firstName" component="div" className="error-message" />
                                                                    </div>
                                                                </div>
                                                                <div className="col-12 col-md-6">
                                                                    {/* <label>Last Name<span> *</span></label> */}
                                                                    <div className="form-group">
                                                                        <Field type="text" name="lastName" autoComplete='off' className="form-control" placeholder="Last Name *" />
                                                                        <ErrorMessage name="lastName" component="div" className="error-message" />
                                                                    </div>
                                                                </div>
                                                                <div className="col-12 col-md-12">
                                                                    {/* <label>Email<span> *</span></label> */}
                                                                    <div className="form-group">
                                                                        <Field type="email" name="email" autoComplete='off' className="form-control" placeholder="Email Address *" />
                                                                        <ErrorMessage name="email" component="div" className="error-message" />
                                                                    </div>
                                                                </div>
                                                                <div className="col-12 col-md-12">
                                                                    {/* <label>Phone<span> *</span></label> */}
                                                                    <div className="form-group">
                                                                        <Field type="number" name="phone" autoComplete='off' className="form-control" placeholder="Phone *" />
                                                                        <ErrorMessage name="phone" component="div" className="error-message" />
                                                                    </div>
                                                                </div>
                                                                <div className="col-12 col-md-12">
                                                                    {/* <label>Password<span> *</span></label> */}
                                                                    <div className="form-group">
                                                                        <Field type="password" name="password" className="form-control" placeholder="Password *" />
                                                                        <ErrorMessage name="password" component="div" className="error-message" />
                                                                    </div>
                                                                </div>
                                                                <div className="col-12 col-md-12">
                                                                    {/* <label>Confirm Password<span> *</span></label> */}
                                                                    <div className="form-group">
                                                                        <Field type="password" name="confirm_password" className="form-control" placeholder="Confirm Password *" />
                                                                        <ErrorMessage name="confirm_password" component="div" className="error-message" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <button type="submit" disabled={!!loading} className='primary-button button-color'>Submit</button>
                                                        </Form>
                                                    )}
                                                </Formik>
                                            </div>
                                            <div className='social-login'>
                                                <LoginSocialGoogle
                                                    client_id={process.env.REACT_APP_GOOGLE_APP_ID}
                                                    scope="openid profile email"
                                                    onResolve={async ({ provider, data }) => {
                                                        let obj = {
                                                            "firstName": data.given_name,
                                                            "lastName": data.family_name,
                                                            "email": data.email,
                                                            "socialLoginId": data.sub,
                                                            "socialLoginType": provider
                                                        }
                                                        await dispatch(socialLogin(obj))
                                                    }}
                                                    onReject={err => {
                                                        console.log(err);
                                                    }}
                                                >
                                                    <button className='oauth-btn' style={{ background: "#000000" }} ><img src='./images/g1.png' alt='' style={{ verticalAlign: 'middle' }} width={20} />&nbsp;&nbsp;Sign in with Google  </button>
                                                </LoginSocialGoogle>

                                                <LoginSocialFacebook
                                                    appId={process.env.REACT_APP_Facebook_APP_ID || ''}
                                                    fieldsProfile={
                                                        'id,first_name,last_name,middle_name,name,name_format,picture,short_name,email,gender'
                                                    }
                                                    onResolve={async ({ provider, data }) => {
                                                        let obj = {
                                                            "firstName": data.first_name,
                                                            "lastName": data.last_name,
                                                            "email": data.email,
                                                            "socialLoginId": data.id,
                                                            "socialLoginType": provider
                                                        }
                                                        await dispatch(socialLogin(obj))
                                                    }}
                                                    onReject={err => {
                                                        console.log(err);
                                                    }}
                                                >
                                                    <button className='oauth-btn' style={{ background: "#000000" }} ><img src='./svg-icons/facebook.svg' alt='' style={{ verticalAlign: 'middle' }} width={20} />&nbsp;&nbsp; Sign in with Facebook</button>
                                                </LoginSocialFacebook>

                                                {/* <LoginSocialApple
                                                    client_id={process.env.REACT_APP_APPLE_ID || ''}
                                                    // scope={'name email'}
                                                    // redirect_uri={REDIRECT_URI}
                                                    // onLoginStart={onLoginStart}
                                                    onResolve={async ({ provider, data }) => {
                                                        let obj = {
                                                            "firstName": data.first_name,
                                                            "lastName": data.last_name,
                                                            "email": data.email,
                                                            "socialLoginId": data.id,
                                                            "socialLoginType": provider
                                                        }
                                                        await dispatch(socialLogin(obj))
                                                    }}
                                                    onReject={err => {
                                                        console.log(err);
                                                    }}
                                                >
                                                    <button className='oauth-btn mt-2' style={{ background: "#000000" }} ><img src='./png-icons/apple.png' alt='' style={{ verticalAlign: 'middle' }} width={20} />&nbsp;&nbsp;Sign In with Apple </button>
                                                </LoginSocialApple> */}
                                            </div>

                                        </Fragment>
                                        :
                                        <div className="otp-fields d-flex flex-column pb-0">
                                            <h6 className='m-0'>
                                                OTP Verification
                                            </h6>
                                            <p style={{ color: '#8D8C8F', fontSize: 16, fontWeight: 400 }}>We have Sent the OTP on your Email {localStorage.getItem('email')}</p>
                                            <Formik initialValues={otp} validationSchema={otpSchema} onSubmit={verify}>
                                                {(formik) => (
                                                    <Form className="text-left">
                                                        <div className="row mt-3">
                                                            <div className="col-12 col-md-12">
                                                                <div className="form-group mt-2">
                                                                    <Field type="text" name="otp" autoComplete='off' className="form-control" placeholder="Enter OTP" />
                                                                    <ErrorMessage name="otp" component="div" className="error-message text-left" />
                                                                </div>
                                                            </div>
                                                            <div className='d-flex'>
                                                                <aside>Did't get OTP?</aside>&nbsp;
                                                                <aside onClick={timer === 0 ? resendOtp : null} style={{ color: timer === 0 ? '#6663FF' : '#8d8c8f', cursor: timer === 0 ? 'pointer' : '', fontWeight: 700 }}>Resend</aside>&nbsp;
                                                                <aside style={{ color: '#1B1A1F', fontWeight: 500 }}>({minutes.toString().padStart(2, '0')}:{seconds.toString().padStart(2, '0')})</aside>
                                                            </div>
                                                        </div>
                                                        <button
                                                            disabled={
                                                                formik.isSubmitting
                                                            }
                                                            className=" mt-3 button-color primary-button position-relative"
                                                        >
                                                            {isOtpResetLoading ? 'Loading...' : "Submit"}
                                                        </button>
                                                    </Form>
                                                )}
                                            </Formik>
                                            {/* <div className="form-group mt-3" style={{ width: "100%" }}>
                                                <input
                                                    type="text"
                                                    autoComplete="off"
                                                    className="form-control"
                                                    onChange={(e) =>
                                                        handleChange(e.target.value)
                                                    }
                                                    style={{ height: 48 }}
                                                    value={otp}
                                                    placeholder="Enter otp"
                                                />
                                            </div> */}


                                        </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div >
    );
};

export default Signup;