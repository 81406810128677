import React, { useEffect, useState } from 'react'
import './recipes.scss';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { getRecipes, getNextRecipes, resetRecipes, resetRecipeLoader, getPlatingImages, getRecipeImages } from '../../Redux/ActionCreators/homepageAction';
import Slider from 'react-slick';
import { Accordion } from 'react-bootstrap';
import Lightbox from '../../utils/lightBox';


export default function Recipes() {
    const [page, setPage] = useState(1);
    const [active, setActive] = useState('-1');
    const [openBox, setOpenBox] = useState(false);
    const [image, setImage] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [count, setCount] = useState(0);
    const location = useLocation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { recipes, hash, isLoadingRecipe, recipeComplete } = useSelector(state => state.home);

    useEffect(() => {
        window.scrollTo(0, 0);
        window.onbeforeunload = function () {
            window.scrollTo(0, 0);
        };
        const firstRender = async () => {
            await dispatch(resetRecipes());
            const { isDay, ...newObj } = location.state.inputData;
            const res = await dispatch(getRecipes(newObj));
            if (res.type === 'GET_RECIPES') {
                for (var i = 0; i < res.payload.rows?.length; i++) {
                    await dispatch(getRecipeImages(res.payload.rows[i], i));
                }
                await setCount(0);
            }

        }
        firstRender();
    }, [])

    const getMoreRecipes = async () => {
        await dispatch(resetRecipeLoader());
        setPage(page + 1)
        let inputData = {
            activeMasterCatalog: location.state.inputData?.activeMasterCatalog,
            activeMasterSlug: location.state.inputData?.activeSlug,
            activeCatalog: location.state.inputData.activeCatalog,
            activeSubCatalog: location.state.inputData.activeSubCatalog,
            subCataLogRemark: location.state.inputData.subCataLogRemark,
            activeCuisine: location.state.inputData.activeCuisine,
            cuisineRemark: location.state.inputData.cuisineRemark,
            activeProductTypes: location.state.inputData.activeProductTypes,
            productTypeRemark: location.state.inputData.productTypeRemark,
            activeProducts: location.state.inputData.activeProducts,
            productRemark: location.state.inputData.productRemark,
            otherNotes: location.state.inputData.other,
            keyword: location.state.inputData.keyword,
            page: page + 1,
            hash: hash,
        }
        const res = await dispatch(getNextRecipes(inputData));
        if (res?.type === 'GET_NEXT_RECIPES') {
            const rec = recipes.concat(res.payload.rows?.map(el => [{ ...el, recipe_image: '/images/placeholder-image.gif', images: [] }][0]))
            let start = count + res.payload.rows?.length
            for (var i = start; i < rec?.length; i++) {
                await dispatch(getRecipeImages(rec[i], i));
            }
        }
    }

    const getPlatingImage = async (detail, i) => {
        await setIsLoading(true);
        await dispatch(getPlatingImages(detail, i));
        await setIsLoading(false);
    }

    const goBack = () => {
        navigate('/')
    }

    const loaderImages = [
        './images/loader-images/loader.gif',
        './images/loader-images/2.png',
        './images/loader-images/3.png',
        './images/loader-images/loader.gif',
        './images/loader-images/4.png',
        './images/loader-images/5.png',
        './images/loader-images/loader.gif',
        './images/loader-images/6.png',
        './images/loader-images/7.png',
        './images/loader-images/loader.gif',
        './images/loader-images/8.png',
        './images/loader-images/9.png',
        './images/loader-images/loader.gif',
        './images/loader-images/10.png',
        './images/loader-images/11.png',
        './images/loader-images/loader.gif',
        './images/loader-images/12.png',
        './images/loader-images/13.png',
        './images/loader-images/loader.gif',
        './images/loader-images/14.png',
        './images/loader-images/15.png',
        './images/loader-images/loader.gif',
    ];

    var settings = {
        speed: 4000,
        autoplay: true,
        fade: true,
        pauseOnHover: false,
        infinite: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
    };
    const { isDay } = location.state.inputData
    const handleAccordion = (i) => {
        if (Number(active) === i) {
            setActive(-1);
        } else {
            setActive(i);
        }
    }

    const openImage = (e, img) => {
        e.preventDefault();
        e.stopPropagation();
        setOpenBox(true);
        setImage(img)
    }

    return (
        < div className='content-holder' >
            <div className='banner-image' >
                <img src='./images/img1.png' alt='' />
                <h5>Recipes</h5>
            </div>

            {
                <div className="content">
                    {recipes ?
                        <Accordion defaultActiveKey={`${active}`} id='accord' >
                            {
                                recipes?.map((el, i) => {
                                    return (
                                        <Accordion.Item key={i} eventKey={`${i}`}>
                                            <Accordion.Header className={Number(active) === i ? 'active-acc' : ''} onClick={() => handleAccordion(i)}>
                                                <div className='d-flex justify-content-between w-100 flex-wrap' style={{ background: Number(active) === i ? '#ffefe5' : '' }}>
                                                    <div className={window.innerWidth > 767 ? 'w-50' : 'w-100'}>
                                                        <h2>{isDay ? `Day ${i + 1} - ` : null} {el?.recipe_name} </h2>
                                                        {window.innerWidth < 767 &&
                                                            <div className='recipe-img-box'>
                                                                <img src={el.recipe_image} alt='' className='recipe-img' />
                                                            </div>
                                                        }
                                                        <div className='mt-4'>
                                                            {
                                                                el.ingredients && (
                                                                    <div>
                                                                        <h3>Ingredients: (Serving {el.serving})</h3>
                                                                        <p dangerouslySetInnerHTML={{ __html: el.ingredients.replaceAll("\n", "<br>") }}></p>
                                                                    </div>
                                                                )
                                                            }
                                                        </div>
                                                    </div>
                                                    {window.innerWidth > 767 ?
                                                        <div className={window.innerWidth > 767 ? 'w-50' : 'w-100'}>
                                                            <div className='recipe-img-box'>
                                                                <img src={el.recipe_image} alt='' className='recipe-img' onClick={(e) => window.innerWidth > 767 ? openImage(e, el.recipe_image) : null} />
                                                            </div>
                                                        </div> :
                                                        null
                                                    }
                                                </div>

                                            </Accordion.Header>
                                            <Accordion.Body className={Number(active) === i ? 'active-acc-body' : ''}>
                                                <div className='row'>
                                                    <div className='col-md-6 col-12'>
                                                        <div className='mt-4'>
                                                            {
                                                                el.instructions && (
                                                                    <div>
                                                                        <h3>Instructions:</h3>
                                                                        <p dangerouslySetInnerHTML={{ __html: el.instructions.replaceAll("\n", "<br>") }}></p>
                                                                    </div>
                                                                )
                                                            }
                                                        </div>
                                                        <div className='mt-4'>
                                                            {
                                                                el.suggestions && (
                                                                    <div>
                                                                        <h3>Suggestions</h3>
                                                                        <p dangerouslySetInnerHTML={{ __html: el.suggestions.replaceAll("\n", "<br>") }}></p>
                                                                    </div>
                                                                )
                                                            }
                                                        </div>
                                                        <div className='mt-4'>
                                                            {
                                                                el.wellness_tips && (
                                                                    <div>
                                                                        <h3>Wellness Tips</h3>
                                                                        <p dangerouslySetInnerHTML={{ __html: el.wellness_tips.replaceAll("\n", "<br>") }}></p>
                                                                    </div>
                                                                )
                                                            }
                                                        </div>
                                                        <div className='mt-4'>
                                                            {
                                                                el.plating_creativity && (
                                                                    <div>
                                                                        <h3>Plating Creativity</h3>
                                                                        {/* <p dangerouslySetInnerHTML={{ __html: el.plating_creativity.replaceAll("\n", "<br>") }}></p> */}

                                                                        <p>
                                                                            {el?.plating_creativity.map(item => <div>{item}</div>)}
                                                                        </p>
                                                                    </div>
                                                                )
                                                            }

                                                            {!el?.images?.length ?
                                                                <div className='view-img'>
                                                                    <button onClick={() => getPlatingImage(el, i)} disabled={isLoading}>{isLoading ? 'Loading Images ...' : 'View Images'}</button>
                                                                </div> :
                                                                null
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className='recipe-right col-md-6 col-12'>
                                                        <div className='mt-4'>
                                                            {
                                                                el?.nutrition && (
                                                                    <div>
                                                                        <h3>Nutrition:</h3>
                                                                        <p dangerouslySetInnerHTML={{ __html: el?.nutrition.replaceAll("\n", "<br>") }}></p>
                                                                    </div>
                                                                )
                                                            }
                                                        </div>
                                                        <div className='mt-4'>
                                                            {
                                                                el.burn_calories && (
                                                                    <div>
                                                                        <h3>Burn Calories</h3>
                                                                        <p dangerouslySetInnerHTML={{ __html: el.burn_calories.replaceAll("\n", "<br>") }}></p>
                                                                    </div>
                                                                )
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className={window.innerWidth > 767 ? 'row mt-4 ms-2' : 'row ps-0 mt-4'}>
                                                    {el?.images?.length ?
                                                        el?.images.slice(0, 5)?.map((image, index) => {
                                                            return (
                                                                <div key={index} className={index === 0 ? (window.innerWidth > 767 ? 'col-md-2 col-6  mt-2' : 'col-md-2 col-6 ps-0 mt-2') : (window.innerWidth > 767 ? 'col-md-2 col-6 ps-0 padd mt-2' : 'col-md-2 col-6 ps-0 padd1 mt-2')} >
                                                                    <img src={image} alt='' className='plating-img' onClick={(e) => window.innerWidth > 767 ? openImage(e, image) : null} />
                                                                </div>
                                                            )
                                                        }) :
                                                        null
                                                    }
                                                </div>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    )
                                })
                            }
                        </Accordion > :
                        null
                    }
                    {
                        isLoadingRecipe ?
                            <div className='text-center'>
                                <Slider {...settings}>
                                    {
                                        loaderImages?.map((el, index) => {
                                            return (
                                                <div key={index} className={index === 0 ? "carousel-item active" : "carousel-item"}>
                                                    <img src={el} className="d-block loader-img" alt="..." />
                                                </div>
                                            )
                                        })
                                    }
                                </Slider>
                            </div>
                            :
                            recipes?.length === 0 ?
                                <div className='no-recipes-found'>No recipe data found<br /><br />
                                    <button className='go-back-btn' onClick={goBack}>Go Back</button>
                                </div> :

                                <div className='text-center'>
                                    {
                                        (recipeComplete) ? null : <button className='more-btn' onClick={getMoreRecipes}>Get More Recipes</button>
                                    }
                                    &nbsp;&nbsp; <button className='go-back-btn' onClick={goBack}>Go Back</button>
                                </div>
                    }

                </div >
            }
            {/* ########################## Lightbox modal ####################### */}
            <Lightbox
                openBox={openBox}
                image={image}
                closeBox={() => setOpenBox(false)}
            />
        </div >
    )
}
