//Login
export const USER_LOGIN = 'USER_LOGIN';
export const FORGET_PASSWORD = 'FORGET_PASSWORD';
export const PASSWORD_CHANGED = 'PASSWORD_CHANGED';


//Signup 
export const USER_REGISTER = 'USER_REGISTER';

//add query
export const ADD_QUERY = 'ADD_QUERY';


//PROFILE
export const GET_PROFILE = 'GET_PROFILE';
export const UPDATE_PROFILE = 'UPDATE_PROFILE';
export const DELETE_PROFILE = 'DELETE_PROFILE';
export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';
export const LOGOUT_USER = 'LOGOUT_USER';

//Home
export const GET_MASTER_CATALOGS = 'GET_MASTER_CATALOGS';
export const GET_CATALOGS = 'GET_CATALOGS';
export const GET_SUBCATALOGS = 'GET_SUBCATALOGS';
export const GET_CUISINES = 'GET_CUISINES';
export const GET_PRODUCT_TYPES = 'GET_PRODUCT_TYPES';
export const GET_PRODUCTS = 'GET_PRODUCTS';
export const GET_NEXT_PRODUCTS = 'GET_NEXT_PRODUCTS';
export const GET_RECIPES = 'GET_RECIPES';
export const GET_NEXT_RECIPES = 'GET_NEXT_RECIPES';
export const GET_RECIPE_IMAGES = 'GET_RECIPE_IMAGES';
export const GET_PLATING_IMAGES = 'GET_PLATING_IMAGES';
export const SET_PRODUCT_TYPES = 'SET_PRODUCT_TYPES';
export const SET_PRODUCT = 'SET_PRODUCT';
export const RESET_PRODUCTS = 'RESET_PRODUCTS';
export const RESET_RECIPES = 'RESET_RECIPES';
export const RESET_RECIPES_LOADER = 'RESET_RECIPES_LOADER';
export const SET_LANGUAGE = 'SET_LANGUAGE';

//BANNER
export const GET_BANNERS = 'GET_BANNERS';
//PRODUCTS

//FAQ
export const GET_FAQS = 'GET_FAQS';

//contents
export const GET_CONTENTS = 'GET_CONTENTS';

//Error
export const GET_ERRORS = 'GET_ERRORS';
export const CLEAR_ERRORS = 'CLEAR_ERRORS';