import { postApiCall } from '../../utils/requests';
import { returnErrors } from '../ActionCreators/errorAction';
import { USER_LOGIN, FORGET_PASSWORD, PASSWORD_CHANGED, USER_REGISTER } from '../Types/types';


// Signup apis

export const userSignup = (signup) => async (dispatch) => {
    try {
        const response = await postApiCall(`/auth/signup`, signup);
        if (response.data.statusCode === 200) {
            localStorage.setItem('email', signup.email)
            return dispatch({ type: USER_REGISTER, payload: response.data.data });
        }
        else {
            returnErrors(dispatch, response.data.message, response.status)
        }
    } catch (error) {
        returnErrors(dispatch, error.response?.data.message, error.response?.status)
    }
}

export const verifyUser = (obj) => async (dispatch) => {
    try {
        const response = await postApiCall(`/auth/verify-signup`, obj);
        if (response.data.statusCode === 200) {
            localStorage.removeItem('email');
            let uid = localStorage.getItem('uid') !== null && localStorage.getItem('uid');
            const token = response.data.data.userDevices.filter(el => el.deviceUuid === uid)[0];
            localStorage.setItem('userAuthToken', token?.authToken);
            localStorage.setItem('uid', token.deviceUuid);
            window.location.href = '/';
            return dispatch({ type: USER_LOGIN, payload: response.data.data });
        }
        else {
            returnErrors(dispatch, response.data.message, response.status);
        }
    } catch (error) {
        returnErrors(dispatch, error.response?.data.message, error.response?.status)
    }
}


//Login apis

export const userLogin = (login) => async (dispatch) => {
    try {
        const response = await postApiCall(`/auth/login`, login);

        if (response.data.statusCode === 200) {
            let uid = localStorage.getItem('uid') !== null && localStorage.getItem('uid');
            const token = response.data.data.userDevices.filter(el => el.deviceUuid === uid)[0];
            localStorage.setItem('userAuthToken', token?.authToken);
            localStorage.setItem('uid', token.deviceUuid);
            window.location.href = '/';
            return dispatch({ type: USER_LOGIN, payload: response.data.data });
        }
        else {
            returnErrors(dispatch, response.data.message, response.status)
        }
    } catch (error) {
        returnErrors(dispatch, error.response?.data.message, error.response?.status)
    }
}

export const forgotPassword = (obj) => async (dispatch) => {
    try {
        const response = await postApiCall(`/auth/send-otp`, obj);
        if (response.data.statusCode === 200) {
            returnErrors(dispatch, response.data.message, response.status);
            localStorage.setItem('email', obj.email)
            return dispatch({ type: FORGET_PASSWORD });
        }
        else {
            returnErrors(dispatch, response.data.message, response.status);
        }
    } catch (error) {
        returnErrors(dispatch, error.response?.data.message, error.response?.status)
    }
}

export const changedPassword = (obj) => async (dispatch) => {
    try {
        const response = await postApiCall(`/auth/update-forgot-password`, obj);
        if (response.data.statusCode === 200) {
            returnErrors(dispatch, response.data.message, response.status);
            localStorage.removeItem('email');
            return dispatch({ type: PASSWORD_CHANGED });
        }
        else {
            returnErrors(dispatch, response.data.message, response.status);
        }
    } catch (error) {
        returnErrors(dispatch, error.response?.data.message, error.response?.status)
    }
}

export const socialLogin = (login) => async (dispatch) => {
    try {
        const response = await postApiCall(`/auth/social-login`, login);

        if (response.data.statusCode === 200) {
            let uid = localStorage.getItem('uid') !== null && localStorage.getItem('uid');
            const token = response.data.data.userDevices.filter(el => el.deviceUuid === uid)[0];
            localStorage.setItem('userAuthToken', token?.authToken);
            localStorage.setItem('uid', token.deviceUuid);
            window.location.href = '/';
            return dispatch({ type: USER_LOGIN, payload: response.data.data });
        }
        else {
            returnErrors(dispatch, response.data.message, response.status)
        }
    } catch (error) {
        returnErrors(dispatch, error.response?.data.message, error.response?.status)
    }
}