import React from 'react'
import { useNavigate } from 'react-router-dom'

export default function Footer() {
    const navigate = useNavigate();
    return (
        <footer className='footer'>
            <h5>Quick Links</h5>
            <ul>
                <li onClick={() => navigate('/faqs')}>FAQs</li>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <li onClick={() => navigate('/privacy-policy')}>Privacy Policy</li>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <li onClick={() => navigate('/legal-policy')}>Legal Policy</li>
            </ul>
            <div className='m-4'>
                <h5>Follow Us on</h5>
                <ul>
                    <li>
                        <a href='https://www.facebook.com/profile.php?id=61561033177402' target='_blank' rel="noreferrer">
                            <img src='./svg-icons/facebook.svg' alt='' width={24} />
                        </a>
                    </li>
                    <li>
                        <a href='https://www.instagram.com' target='_blank' rel="noreferrer">
                            <img src='./svg-icons/instagram.svg' alt='' width={24} />
                        </a>
                    </li>
                    <li>
                        <a href='https://www.twitter.com' target='_blank' rel="noreferrer">
                            <img src='./svg-icons/twit.svg' alt='' width={28} />
                        </a>
                    </li>
                    <li>
                        <a href='https://www.youtube.com' target='_blank' rel="noreferrer">
                            <img src='./svg-icons/yt.svg' alt='' width={28} />
                        </a>
                    </li>
                </ul>

            </div>
        </footer >
    )
}
