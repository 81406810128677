import { GET_PROFILE } from '../Types/types';

let intialState = {
    profileDetails: null
}

export default function profileReducer(state = intialState, action) {
    switch (action.type) {
        case GET_PROFILE:
            return { ...state, profileDetails: action.payload };
        default:
            return state;
    }
}
