
export function SampleNextArrow(props) {
    const { className, onClick } = props;
    return (
        <div
            className={className}
            onClick={onClick}
        >
            <img src="./png-icons/right-arrow.png" width='25px' alt='' />
        </div>
    );
}

export function SamplePrevArrow(props) {
    const { className, onClick } = props;
    return (
        <div
            className={className}
            onClick={onClick}
        >
            <img src="./png-icons/left-arrow.png" width='25px' alt='' />
        </div>
    );
}