import { USER_LOGIN } from '../Types/types';

let intialState = {
    isAuthorized: false
}

export default function authReducer(state = intialState, action) {
    let stateCopy = JSON.parse(JSON.stringify(state));
    switch (action.type) {
        case USER_LOGIN:
            stateCopy.user = action.payload;
            localStorage.setItem('loginDetails', JSON.stringify(action.payload.userDevices));
            return stateCopy;
        default:
            return stateCopy;
    }
}
