import { deleteApiCall, getApiCall, postApiCall, putApiCall } from '../../utils/requests';
import { returnErrors } from '../ActionCreators/errorAction';
import { GET_PROFILE, LOGOUT_USER, UPDATE_PROFILE, CHANGE_PASSWORD, DELETE_PROFILE } from '../Types/types';


export const getProfile = () => async (dispatch) => {
    try {
        const response = await getApiCall(`/profile`);
        if (response.data.statusCode === 200) {
            dispatch({ type: GET_PROFILE, payload: response.data.data });
        }
        else {
            returnErrors(dispatch, response.data.message, response.status);
        }
    } catch (error) {
        returnErrors(dispatch, error.response?.data.message, error.response?.status)
    }
}

export const logOutUser = () => async (dispatch) => {
    try {
        const response = await postApiCall(`/profile/logout`);
        if (response.data.statusCode === 200) {
            dispatch({ type: LOGOUT_USER, });
            returnErrors(dispatch, response.data.message, response.status)
        }
        else {
            returnErrors(dispatch, response.data.message, response.status)
        }
    } catch (error) {
        returnErrors(dispatch, error.response?.data.message, error.response?.status)
    }
}

export const updateProfile = (obj) => async (dispatch) => {
    try {
        const response = await putApiCall(`/profile`, obj);
        if (response.data.statusCode === 200) {
            returnErrors(dispatch, response?.data?.message, response.status);
            return dispatch({ type: UPDATE_PROFILE });
        }
        else {
            returnErrors(dispatch, response.data.message, response.status)
        }
    } catch (error) {
        returnErrors(dispatch, error.response?.data.message, error.response?.status)
    }
}

export const deleteProfile = () => async (dispatch) => {
    try {
        const response = await deleteApiCall(`/profile/account`);
        if (response.data.statusCode === 200) {
            returnErrors(dispatch, 'Account has been deleted succesfully', response.status);
            return dispatch({ type: DELETE_PROFILE });
        }
        else {
            returnErrors(dispatch, response.data.message, response.status)
        }
    } catch (error) {
        returnErrors(dispatch, error.response?.data.message, error.response?.status)
    }
}

export const changePassword = (obj) => async (dispatch) => {
    try {
        const response = await putApiCall(`/profile/change-password`, obj);
        if (response.data.statusCode === 200) {
            returnErrors(dispatch, response?.data?.message, response.status);
            return dispatch({ type: CHANGE_PASSWORD });
        }
        else {
            returnErrors(dispatch, response.data.message, response.status)
        }
    } catch (error) {
        returnErrors(dispatch, error.response?.data.message, error.response?.status)
    }
}

