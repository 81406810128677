import React from 'react'
import { Link } from 'react-router-dom'

export default function Notfound() {
    return (
        <div style={{ width: '100%', height: '100vh', background: "#fff" }}>
            <div className='no-found-page d-flex flex-column align-items'>
                <img src='./images/notfound.gif' alt='' style={{ width: 200, height: 200, }} />
                Not found this page
                Click on this page&nbsp;&nbsp;
                <Link to='/'>
                    Home
                </Link>
            </div>

        </div>
    )
}
